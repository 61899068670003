<template>
  <span class="text-nowrap">{{ formatDateTime(data) }}</span>
</template>

<script>
import {formatDateTime} from "@core/utils/filter";

export default {
  methods: {
    formatDateTime,
  },
  props: ['data'],
}
</script>

<style scoped>

</style>