<template>
  <span class="text-nowrap">
    <span v-if="!dropdown">
      <feather-icon
          :id="`row-${data.item.id}-remove`"
          icon="TrashIcon"
          size="16"
          @click="removeRecord(data.item.id)"/>
      <b-tooltip title="Çıkar" class="cursor-pointer" :target="`row-${data.item.id}-remove`" v-if="!tooltipNotVisible"/>
    </span>

    <b-dropdown-item @click="removeRecord(data.item.id)" v-if="dropdown">
      <feather-icon icon="TrashIcon"/>
      <span class="align-middle ml-50">Çıkar</span>
    </b-dropdown-item>
  </span>
</template>

<script>
import {BDropdownItem, BTooltip} from "bootstrap-vue";
import store from "@/store";

export default {
  name: "RemoveButtonTable",
  components: {BDropdownItem, BTooltip},
  props: {
    reFetchData: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    tooltipNotVisible: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    removeRecord(id) {
      this.$swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeItem', {id: id}).then(response => {
            if (response.data.success) {
              this.reFetchData()
              this.$swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt çıkarıldı.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    },
  }
}
</script>