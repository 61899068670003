import {isToday} from './utils'
import axios from '@axios'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
    if (!value) return ''
    const str = value.toString()

    const arr = str.split(replacer)
    const capitalizedArray = []
    arr.forEach(word => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalizedArray.push(capitalized)
    })
    return capitalizedArray.join(' ')
}

export const recordStatusFormat = value => {
    return value === true || value === 'True' ? 'Kullanımda' : 'Kullanım Dışı'
}

export const isDefaultFormat = value => {
    return value ? 'Evet' : 'Hayır'
}

export const recordStatusVariant = value => {
    return value === true || value === 'True' ? 'success' : 'secondary'
}

export const recordFormStatusVariant = value => {
    if (value.isCancelled === true) {
        return 'danger';
    } else if (value.formStatusId === 'WAITING') {
        return 'warning';
    } else if (value.formStatusId === 'PROCESSING') {
        return 'primary';
    } else if (value.formStatusId === 'STOPPED') {
        return 'secondary';
    } else if (value.formStatusId === 'CONTINUES') {
        return 'info';
    } else if (value.formStatusId === 'COMPLETED') {
        return 'success';
    } else {
        return 'secondary'
    }
}

export const statusOptions = [
    {label: 'Kullanım Dışı', value: false},
    {label: 'Kullanımda', value: true},
]

export const yesNoOptions = [
    {label: 'Hayır', value: false},
    {label: 'Evet', value: true},
]

export const perPageOptions = [10, 25, 50, 100]

export const getApiFile = (filePath) => {
    if (filePath !== null && filePath !== '' && filePath !== undefined) {
        return axios.defaults.baseURL + '/file?path=' + filePath;
    } else {
        return '';
    }
}
export const avatarText = value => {
    if (!value) return ''
    const nameArray = value.split(' ')
    return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
}) => {
    if (!value) return value
    return new Intl.DateTimeFormat('tr-TR', formatting).format(new Date(value))
}

export const formatDateTime = (value, locale = 'tr-TR', formatting = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
}) => {
    if (!value) return value
    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value))
}

export const formatTime = (value, locale = 'tr-TR', formatting = {
    hour: 'numeric',
    minute: 'numeric',
}) => {
    if (!value) return value
    return new Intl.DateTimeFormat(locale, formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: 'short', day: 'numeric'}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: 'numeric', minute: 'numeric'}
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')
