<template>
  <span class="text-nowrap">
    <feather-icon
        :id="`row-${data.item.id}-edit`"
        icon="EditIcon"
        size="16"
        class="mx-1"
        @click="$router.push({ name: routerName, params: { id: data.item.id }})"/>
    <b-tooltip title="Değiştir" class="cursor-pointer" :target="`row-${data.item.id}-edit`" v-if="!tooltipNotVisible"/>
  </span>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  components: {BTooltip},
  props: ["data", "routerName", "tooltipNotVisible"]
}
</script>

<style scoped>

</style>