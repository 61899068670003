<template>
  <b-badge pill :variant="`light-${recordStatusVariant(data)}`" class="text-capitalize">
    {{ recordStatusFormat(data) }}
  </b-badge>
</template>

<script>
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter";
import {BBadge} from "bootstrap-vue";

export default {
  components: {BBadge},
  methods: {
    recordStatusFormat,
    recordStatusVariant,
  },
  props: ['data'],
}
</script>

<style scoped>

</style>